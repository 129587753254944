define('retro-ninja/models/vote', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    voter: attr('string'),
    isVoteUp: attr('boolean'),
    createdAt: attr('moment', { defaultValue: (0, _moment.default)() })
  });
});