define('retro-ninja/components/arena/action-card', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    classNames: ['ui', 'fluid', 'card'],

    vote: function vote(isVoteUp) {
      if (this.get('action.selfVote')) {
        var vote = this.get('action.selfVote');
        if (vote.get('isVoteUp') === isVoteUp) {
          vote.destroyRecord();
        }
        vote.set('isVoteUp', isVoteUp);
        vote.save();
        this.get('action').save();
      } else {
        this.get('action').get('votes').addObject(this.get('store').createRecord('vote', {
          voter: this.get('session.currentUser.uid'),
          isVoteUp: isVoteUp
        }));
        this.get('action').save();
      }
    },


    actions: {
      voteUp: function voteUp() {
        this.vote(true);
      },
      voteDown: function voteDown() {
        this.vote(false);
      },
      delete: function _delete() {
        this.get('action').destroyRecord();
      }
    }
  });
});