define('retro-ninja/router', ['exports', 'ember', 'retro-ninja/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Router = _ember.default.Router;


  var router = Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  router.map(function () {
    this.authenticatedRoute('arena');
  });

  exports.default = router;
});