define('retro-ninja/services/state', ['exports', 'ember', 'moment', 'lodash'], function (exports, _ember, _moment, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.humanizeSeconds = undefined;
  var A = _ember.default.A,
      Service = _ember.default.Service,
      service = _ember.default.inject.service,
      O = _ember.default.Object,
      computed = _ember.default.computed,
      run = _ember.default.run,
      observer = _ember.default.observer;
  var humanizeSeconds = exports.humanizeSeconds = function humanizeSeconds(secondsSource) {
    var duration = _moment.default.duration(secondsSource, 's');
    var hours = duration.get('h');
    var minutes = duration.get('m');
    var seconds = duration.get('s');
    return '' + (hours ? _lodash.default.padLeft(hours.toString(), 2, '0') + ':' : '') + _lodash.default.padLeft(minutes.toString(), 2, '0') + ':' + _lodash.default.padLeft(seconds.toString(), 2, '0');
  };

  exports.default = Service.extend({

    store: service(),
    session: service(),
    authManager: service(),
    users: A([]),
    setting: O.create({}),
    timeRemaining: null,

    activeUsers: computed.filterBy('users', 'isActive', true),

    startObserver: observer('setting.isRetrospectiveRunning', function () {
      if (this.get('setting.isRetrospectiveRunning')) {
        this.startTimer();
      } else {
        this.stopTimer();
      }
    }),

    humanizedTimeRemaining: computed('timeRemaining', function () {
      if (!this.get('timeRemaining')) {
        return null;
      }
      return humanizeSeconds(this.get('timeRemaining'));
    }),

    startTimer: function startTimer() {
      var _this = this;

      if (this.get('setting.isRetrospectiveRunning')) {
        var seconds = this.get('setting.retrospectiveEndsAt').diff((0, _moment.default)(), 'seconds');
        if (seconds > 1) {
          this.set('timeRemaining', seconds);
        } else {
          this.set('timeRemaining', null);
        }
        this.set('countdownDelay', run.later(function () {
          _this.startTimer();
        }, 1000));
      } else {
        this.stopTimer();
      }
    },
    stopTimer: function stopTimer() {
      this.set('timeRemaining', null);
      if (this.get('countdownDelay')) {
        run.cancel(this.get('countdownDelay'));
      }
    },
    initialize: function initialize() {
      var _this2 = this;

      this.get('store').findAll('setting').then(function (settings) {
        if (settings.get('length') > 0) {
          _this2.set('setting', settings.objectAt(0));
        } else {
          throw new Error();
        }
      }).catch(function () {
        var setting = _this2.get('store').createRecord('setting');
        setting.save();
        _this2.set('setting', setting);
      });
      this.get('store').findAll('user').then(function (users) {
        _this2.set('users', users);
        var userInfo = O.create(_this2.get('session.currentUser')).getProperties('uid', 'displayName', 'email', 'photoURL');
        var selfUser = _this2.get('users').findBy('uid', userInfo.uid);
        userInfo.connectedAt = (0, _moment.default)();
        userInfo.lastPingAt = (0, _moment.default)();
        if (selfUser) {
          selfUser.setProperties(userInfo);
        } else {
          selfUser = _this2.get('store').createRecord('user', userInfo);
        }
        selfUser.save();
        _this2.set('authManager.currentUser', selfUser);
      });
    }
  });
});