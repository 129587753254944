define('retro-ninja/components/modals/admin-modal', ['exports', 'retro-ninja/components/modals/modal-base', 'ember', 'moment'], function (exports, _modalBase, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _modalBase.default.extend({
    isSmall: true,

    timerMinutes: computed.oneWay('state.setting.timerMinutes'),

    actions: {
      save: function save(retrospectiveChanged) {
        if (retrospectiveChanged && this.get('state.setting.isRetrospectiveRunning')) {
          this.set('state.setting.retrospectiveStartedAt', (0, _moment.default)());
          this.send('saveTimerMinutes');
        }
        this.get('state.setting').save();
      },
      saveTimerMinutes: function saveTimerMinutes() {
        this.set('state.setting.timerMinutes', this.get('timerMinutes'));
        this.set('state.setting.retrospectiveEndsAt', this.get('state.setting.retrospectiveStartedAt').clone().add(parseInt(this.get('timerMinutes')), 'minutes'));
        this.send('save');
      },
      resetTimer: function resetTimer() {
        this.send('save', true);
      }
    }
  });
});