define('retro-ninja/controllers/arena', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({

    startActions: computed('model.[]', 'model.@each.{position,type,isDeleted}', function () {
      return this.get('model').filterBy('type', 'start').filterBy('isDeleted', false).sortBy('position');
    }),

    stopActions: computed('model.[]', 'model.@each.{position,type,isDeleted}', function () {
      return this.get('model').filterBy('type', 'stop').filterBy('isDeleted', false).sortBy('position');
    }),

    continueActions: computed('model.[]', 'model.@each.{position,type,isDeleted}', function () {
      return this.get('model').filterBy('type', 'continue').filterBy('isDeleted', false).sortBy('position');
    }),

    actions: {
      reorderActions: function reorderActions(sortedActions) {
        sortedActions.forEach(function (action, index) {
          action.set('position', index);
          action.save();
        });
      },
      addAction: function addAction(type) {
        var _this = this;

        var actionKey = type + 'Action';
        var actionLoadingKey = type + 'ActionLoading';
        var actionContent = this.get(actionKey);
        if (actionContent && actionContent.trim() !== '') {
          this.set(actionLoadingKey, true);
          var action = this.store.createRecord('action', {
            type: type,
            creator: this.get('session.currentUser.uid'),
            content: actionContent,
            votes: []
          });
          action.save().then(function () {
            _this.set(actionKey, null);
          }).finally(function () {
            _this.set(actionLoadingKey, false);
          });
        }
      }
    }
  });
});