define('retro-ninja/services/auth-manager', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Service = _ember.default.Service,
      computed = _ember.default.computed,
      service = _ember.default.inject.service;
  exports.default = Service.extend({
    session: service(),

    isAdminPanelOpen: false,

    isAdmin: computed('session.currentUser.email', function () {
      return ['niranjan.rajendran@canopy.cloud', 'niranjan.rajendran@mesitis.com'].includes(this.get('session.currentUser.email'));
    })
  });
});