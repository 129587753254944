define('retro-ninja/controllers/index', ['exports', 'ember', 'lodash'], function (exports, _ember, _lodash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      htmlSafe = _ember.default.String.htmlSafe,
      run = _ember.default.run;
  exports.default = Controller.extend({
    actions: {
      signIn: function signIn() {
        var _this = this;

        this.setProperties({
          errorMessage: null,
          isLoading: true
        });
        this.get('session').open('firebase', { provider: 'google' }).then(function (data) {
          if (_lodash.default.endsWith(data.currentUser.email, '@canopy.cloud') || _lodash.default.endsWith(data.currentUser.email, '@mesitis.com')) {
            run.next(function () {
              _this.get('state').initialize();
            });
            run.later(function () {
              _this.transitionToRoute('arena');
            }, 1500);
          } else {
            _this.get('session').close();
            _this.set('errorMessage', htmlSafe('Please use your <strong>@canopy.cloud</strong> email address.'));
          }
        }).catch(function (e) {
          if (e.code && e.code === 'auth/popup-closed-by-user') {
            _this.set('errorMessage', 'The authentication flow was pre-maturely cancelled. Please try again.');
          } else {
            _this.set('errorMessage', 'An unexpected error occurred. Please try again.');
          }
        }).finally(function () {
          return _this.set('isLoading', false);
        });
      }
    }

  });
});