define('retro-ninja/routes/arena', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('state.setting.isRetrospectiveRunning')) {
        this.get('state').startTimer();
      } else {
        this.get('state').stopTimer();
      }
    },
    model: function model() {
      return this.store.findAll('action');
    },


    actions: {
      willTransition: function willTransition() {}
    }
  });
});