define('retro-ninja/components/footer-main', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    tagName: 'footer',
    classNames: ['ui', 'vertical', 'footer', 'segment']
  });
});