define('retro-ninja/routes/application', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      run = _ember.default.run;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      return this.get('session').fetch().then(function () {
        run.next(function () {
          _this.get('state').initialize();
        });
      }).catch(function () {});
    },
    startUserLivePing: function startUserLivePing() {
      var _this2 = this;

      run.later(function () {
        if (_this2.get('authManager.currentUser')) {
          var user = _this2.get('authManager.currentUser');
          user.set('lastPingAt', (0, _moment.default)());
          user.save();
        }
        _this2.startUserLivePing();
      }, 60000);
    },
    afterModel: function afterModel() {
      this.startUserLivePing();
    },


    actions: {
      accessDenied: function accessDenied() {
        this.transitionTo('index');
      },
      signOut: function signOut() {
        var _this3 = this;

        this.get('session').close().then(function () {
          _this3.transitionTo('index');
          location.reload();
        });
      }
    }
  });
});