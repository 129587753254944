define('retro-ninja/routes/index', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('arena');
      }
    }
  });
});