define('retro-ninja/components/modals/modal-base', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      observer = _ember.default.observer,
      merge = _ember.default.merge,
      assign = _ember.default.assign,
      testing = _ember.default.testing;
  exports.default = Component.extend({
    tagName: 'div',
    classNames: ['ui', 'modal'],
    classNameBindings: ['isFullScreen:fullscreen', 'isSmall:small', 'isLarge:large'],

    openObserver: observer('isOpen', function () {
      if (this.get('isOpen')) {
        this.$().modal('show');
      } else {
        this.$().modal('hide');
      }
    }),

    close: function close() {
      this.set('isOpen', false);
    },
    open: function open() {
      this.set('isOpen', true);
    },


    actions: {
      close: function close() {
        this.close();
      }
    },

    didRender: function didRender() {
      this._super.apply(this, arguments);
      try {
        this.$().modal('refresh');
      } catch (ignored) {/* ignored exception */}
    },
    willInitSemantic: function willInitSemantic() {
      var _this = this;

      var settings = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

      var defaultOptions = {
        detachable: false,
        duration: testing ? 0 : 300,
        dimmerSettings: {
          dimmerName: this.get('elementId') + '-modal-dimmer',
          variation: 'inverted'
        },
        onHide: function onHide() {
          _this.close();
          if (_this.get('onHide')) {
            _this.onHide();
          }
        },
        onDeny: function onDeny() {
          if (_this.get('onDeny')) {
            _this.onDeny();
          }
          return true;
        },
        onApprove: function onApprove() {
          if (_this.get('onApprove')) {
            _this.onApprove();
          }
          return true;
        },

        onVisible: function onVisible() {
          _this.set('isOpen', true);
          _this.$().modal('refresh');
          _this.$('[data-content]').popup({
            inline: true
          });
          if (_this.get('onVisible')) {
            _this.onVisible();
          }
        }
      };

      var options = this.get('options') ? merge(defaultOptions, this.get('options')) : defaultOptions;
      assign(settings, options);
      return settings;
    },
    didInitSemantic: function didInitSemantic() {
      if (this.get('isOpen')) {
        this.$().modal('show');
      }
    },
    didInsertElement: function didInsertElement() {
      var settings = this.willInitSemantic();
      this.$().modal(settings);
      this.didInitSemantic();
    }
  });
});