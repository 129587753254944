define('retro-ninja/models/action', ['exports', 'ember-data', 'moment', 'ember'], function (exports, _emberData, _moment, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      service = _ember.default.inject.service;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend({
    session: service(),

    type: attr('string'),
    content: attr('string'),
    creator: attr('string'),
    position: attr('number', { defaultValue: 100 }),
    createdAt: attr('moment', { defaultValue: (0, _moment.default)() }),

    votes: hasMany('vote', { async: false, inverse: null }),

    upVotes: computed('votes.[]', 'votes.@each.isVoteUp', function () {
      return this.get('votes').filterBy('isVoteUp', true);
    }),

    downVotes: computed('votes.[]', 'votes.@each.isVoteUp', function () {
      return this.get('votes').filterBy('isVoteUp', false);
    }),

    votesCount: computed('votes.[]', 'votes.@each.isVoteUp', function () {
      return this.get('votes').filterBy('isVoteUp', true).length - this.get('votes').filterBy('isVoteUp', false).length;
    }),

    hasVotedUp: computed('selfVote', 'selfVote.isVoteUp', function () {
      return this.get('selfVote') && this.get('selfVote.isVoteUp');
    }),

    hasVotedDown: computed('selfVote', 'selfVote.isVoteUp', function () {
      return this.get('selfVote') && !this.get('selfVote.isVoteUp');
    }),

    selfVote: computed('votes.[]', function () {
      return this.get('votes').findBy('voter', this.get('session.currentUser.uid'));
    })
  });
});