define('retro-ninja/components/nav-bar', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    actions: {
      openAdminPanel: function openAdminPanel() {
        this.set('authManager.isAdminPanelOpen', true);
      }
    }
  });
});