define('retro-ninja/initializers/blanket', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {

    var inject = function inject(property, what) {
      application.inject('controller', property, what);
      application.inject('component', property, what);
      application.inject('route', property, what);
    };

    inject('config', 'service:config');
    inject('session', 'service:session');
    inject('authManager', 'service:auth-manager');
    inject('store', 'service:store');
    inject('state', 'service:state');
    inject('torii', 'service:torii');
    inject('notify', 'service:notify');
    application.inject('component', 'routing', 'service:-routing');
  }

  exports.default = {
    name: 'blanket',
    initialize: initialize
  };
});