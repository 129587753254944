define('retro-ninja/models/setting', ['exports', 'ember-data', 'moment'], function (exports, _emberData, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    isRetrospectiveRunning: attr('boolean', { defaultValue: false }),
    retrospectiveStartedAt: attr('moment', { defaultValue: (0, _moment.default)() }),
    retrospectiveEndsAt: attr('moment', { defaultValue: (0, _moment.default)().add(120, 'minutes') }),

    timerMinutes: attr('number', { defaultValue: 120 }),
    allowAdditions: attr('boolean', { defaultValue: false }),
    allowReOrdering: attr('boolean', { defaultValue: false }),
    allowVoteUp: attr('boolean', { defaultValue: false }),
    allowVoteDown: attr('boolean', { defaultValue: false }),
    allowVotesViewing: attr('boolean', { defaultValue: false }),
    allowDelete: attr('boolean', { defaultValue: false })
  });
});