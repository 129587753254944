define('retro-ninja/models/user', ['exports', 'ember-data', 'moment', 'ember'], function (exports, _emberData, _moment, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    uid: attr('string'),
    displayName: attr('string'),
    email: attr('string'),
    photoURL: attr('string'),
    connectedAt: attr('moment', { defaultValue: (0, _moment.default)() }),
    lastPingAt: attr('moment', { defaultValue: (0, _moment.default)() }),

    isActive: computed('lastPingAt', function () {
      return (0, _moment.default)().diff(this.get('lastPingAt'), 'minutes') <= 2;
    })
  });
});